*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.color-me{
    color:blue;
}
Al

.backcolor{
    background-color:     white;
    ;
    /* background-color: #4e1785; */
}


.nav-item:hover {
    background-color: #845eaa;
    color:white;
    transition: all 0.20s ease-out;


}


 


.freetutorial{
    background-color: #6A52A0;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block;
}



