#product {
    background-image: url(../../Images/background.webp);
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;

}

.color {
    background-color: #f8f6fa;
}

/* body {
    background: linear-gradient(90deg, #e8e8e8 50%, #3d009e 50%);
} */

.portfolio {
    padding: 6%;
    text-align: center;
}

.heading {
    background: #fff;
    padding: 1%;
    text-align: left;
    box-shadow: 0px 0px 4px 0px #545b62;
}

.heading img {
    width: 10%;
}

.bio-info {
    padding: 5%;
    background: #fff;
    box-shadow: 0px 0px 4px 0px #b0b3b7;
}

.name {
    font-family: 'Charmonman', cursive;
    font-weight: 500;
}
.mtop{
    margin-top: 100px;
}

.bio-image {
    text-align: center;
}
.fsize{
    font-size: 50px;
}

.bio-image img {
    border-radius: 50%;
}

.bio-content {
    text-align: left;
}

.bio-content p {
    font-weight: 600;
    font-size: 30px;
}